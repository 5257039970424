/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './reset.scss'
import { API_URL } from '../ApiURL';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';


const ResetPassword = () => {
    const [inputType, setInputType] = useState("password")
    const [inputType2, setInputType2] = useState("password")

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('token');
    const [mainLoader, setMainLoader] = useState(false);
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters and contain 1 capital letter 1 numeric and 1 special character')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required')
    });

    // console.log("id",id)
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        setMainLoader(true)
        axios.post(`${API_URL}/api1/user/forgetpassword`, { token: id, password: data.password })
            .then((res) => {
                setMainLoader(false)
                // alert(res.msg)
                toast('Reset Password Successfully !', {
                });
                return res;
            }).catch((err) => {
                setMainLoader(false)
                // alert(err.msg)
                toast.error('password reset unsuccessful', {
                });
                return err;
            })
    }
    const changeInputType = (data) => {
        if (inputType == "password") {
            setInputType("text")
        }
        else {
            setInputType("password")
        }
    }
    const changeInputType2 = (data) => {
        if (inputType2 == "password") {
            setInputType2("text")
        }
        else {
            setInputType2("password")
        }
    }
    return (
        <>
            <section className="main-reffrel1">
                <div className='row'>
                    <div className='col-sm-12 m-auto p-0'>
                        <div className='log-img'>
                        <img src='\mainlogo.svg' alt='img' className='img-fluid' />
                        </div>
                       
                    </div>
                </div>
                <div className='main_box col-sm-11 col-lg-4'>

                    <div className="row">
                        <div className="col-sm-12 m-auto">
                            <div className="cmn-tile-style">
                                <h3 className='reset_heading'>Create New Password</h3>
                                <p className='reset_para'>Enter your new pass phrase. It must be a minimum of 8 characters and include capital numeric and special character</p>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group inputType">
                                        {/* <label>New Password</label> */}
                                        <input type={inputType} name="password" {...register('password')}
                                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                            placeholder="Enter Password" />
                                        <div className="invalid-feedback">{errors.password?.message}</div>
                                        <div className='picture'>
                                            {inputType == 'password' ? <svg
                                                onClick={changeInputType}
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-eye-off cursor-pointer"
                                                width="32"
                                                height="32"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="#A3A3A3"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <line x1="3" y1="3" x2="21" y2="21" />
                                                <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                                <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                            </svg> : <svg
                                                onClick={changeInputType}
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-eye-off cursor-pointer"
                                                width="32"
                                                height="32"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="#A3A3A3"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                                <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                            </svg>}
                                        </div>


                                    </div>
                                    {/* <button className="toggle-button" onClick={changeInputType} >click</button>
                                    <button className="toggle-button2" onClick={changeInputType2} >click</button> */}

                                    <div className="form-group inputType2">
                                      
                                        <input name="confirmPassword" type={inputType2}
                                            {...register('confirmPassword')}
                                            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                            placeholder="Enter confirm Password" />
                                        <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                                        <div className='picture2'>
                                            {inputType2 == 'password' ? <svg
                                                onClick={changeInputType2}
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-eye-off cursor-pointer"
                                                width="32"
                                                height="32"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="#A3A3A3"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <line x1="3" y1="3" x2="21" y2="21" />
                                                <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                                <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                            </svg> : <svg
                                                onClick={changeInputType2}
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-eye-off cursor-pointer"
                                                width="32"
                                                height="32"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="#A3A3A3"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                                <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                            </svg>}
                                        </div>
                                    </div>
                                    <button type="submit" className={!mainLoader ? "btn-common" : 'btn-common1' } >Save</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPassword;
